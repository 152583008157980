import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import ClientSlider from "../Elements/ClientSlider";
import ServiceBox from "../Elements/ServiceBox";
import FullButton from "../Buttons/FullButton";
import { useNavigate } from "react-router-dom";

import AddImage2 from "../../assets/img/add/2.png";
import MiningLogo from "../../assets/img/serviceslogos/MiningServices.png";
import EngineeringLogo from "../../assets/img/serviceslogos/Engineering.png";
import SafetyLogo from "../../assets/img/serviceslogos/Safety.png";
import SecurityLogo from "../../assets/img/serviceslogos/Security.png";
import TrainingLogo from "../../assets/img/serviceslogos/trainingservices.png";

import MiningBg from "../../assets/img/servicebgs/MiningServices.png";
import SafetyBg from "../../assets/img/servicebgs/Saf.png";
import EngineeringBg from "../../assets/img/servicebgs/Engineering.png";
import TrainingBg from "../../assets/img/servicebgs/trainingservices.png";
import SecurityBg from "../../assets/img/servicebgs/Security.png";

import Dots from "../../assets/svg/Dots";

export default function Services() {
  const servicesRef = useRef(null);
  const navigate = useNavigate();
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleKnowMoreClick = () => {
    navigate('/AboutUs');
  };
 

  const handleServiceClick = (id) => {
    navigate(`/services/${id}`);
  };

  useEffect(() => {
    const handleScroll = () => {
      const servicesSection = servicesRef.current;
      if (!servicesSection) return;

      const rect = servicesSection.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      if (rect.top <= windowHeight && rect.bottom >= 0) {
        servicesSection.classList.add("animate");
      } else {
        servicesSection.classList.remove("animate");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Wrapper id="services" ref={servicesRef}>
      <Section className="whiteBg" style={{ padding: "50px 0" }}>
        <Container>
          <ClientSlider />
        </Container>
      </Section>
      <Section className="darkBg">
        <Container>
          <HeaderInfo>
            <h1 className="extraBold">Our Comprehensive Services</h1>
            <p>
              At our mining company, we offer a wide array of services designed to optimize your mining operations and ensure safety and efficiency every step of the way.
            </p>
          </HeaderInfo>
          <Carousel>
            {serviceData.map((service, index) => (
              <ServiceBoxWrapper
                key={index}
                bgImage={service.bgImage}
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
                className={hoveredIndex === index ? "hovered" : ""}
                onClick={() => handleServiceClick(service.id)}
              >
                <div className="background" style={{ backgroundImage: `url(${service.bgImage})` }} />
                <div className="content">
                  <ServiceBox
                    icon={<ServiceIcon src={service.logo} alt={service.title} />}
                    title={service.title}
                    subtitle={service.subtitle}
                    customStyles={serviceBoxStyles}
                  />
                </div>
              </ServiceBoxWrapper>
            ))}
          </Carousel>
          <Emptyslot />
        </Container>
        <Section className="yellowBg">
          <Container>
            <Advertising className="flexSpaceCenter">
              <AddLeft>
                {/* <h4 className="font15 semiBold">A few words about Planup Group</h4> */}
                <h2 className="font40 extraBold">About CEO</h2>
                <p className="font12">
                  Established on February 13, 2023, by Mr. Duke Lamptey, a safety engineering specialist and businessman. Motivated by over 20 business strategies, Duke built his own future, creating jobs for millions of people, especially the youth. Duke's inspiration comes from his late father RTD WO1 David Lamptey, who made him a martial art fighter in Judo, Aikido, Karate, and Taekwondo.
                </p>
                <ButtonsRow className="flexNullCenter" style={{ margin: "30px 0" }}>
                  <ButtonWrapper>
                    <FullButton title="Know More" action={handleKnowMoreClick} aria-label="Know More" />
                  </ButtonWrapper>
                  {/* <ButtonWrapper style={{ marginLeft: "15px" }}>
                    <FullButton title="Contact Us" action={clickclick} border aria-label="Contact Us Button" />
                  </ButtonWrapper> */}
                </ButtonsRow>
              </AddLeft>
              <AddRight>
                <AddRightInner>
                  <ImageRow className="flexNullCenter">
                    <AddImgWrapper>
                      <img src={AddImage2} alt="office" />
                    </AddImgWrapper>
                  </ImageRow>
                </AddRightInner>
                <DotsWrapper>
                  <Dots />
                </DotsWrapper>
              </AddRight>
            </Advertising>



            
          </Container>
        </Section>
      </Section>
    </Wrapper>
  );
}

const serviceData = [
  {
    id: "mining-services",
    title: "Mining Services",
    subtitle: "We provide comprehensive mining solutions tailored to your project needs, from exploration to extraction, utilizing cutting-edge technology and industry expertise.",
    logo: MiningLogo,
    bgImage: MiningBg
  },
  {
    id: "safety-services",
    title: "Safety Services",
    subtitle: "Ensuring the safety of our workforce and the integrity of operations is paramount. Our safety services encompass risk assessment and safety protocols implementation.",
    logo: SafetyLogo,
    bgImage: SafetyBg
  },
  {
    id: "engineering-services",
    title: "Engineering Services",
    subtitle: "Our Engineering services encompass a wide range of expertise, from project planning and design to implementation and maintenance. We leverage cutting-edge technology....",
    logo: EngineeringLogo,
    bgImage: EngineeringBg
  },
  {
    id: "training-services",
    title: "Training Services",
    subtitle: "Our training services are tailored to equip mining personnel with the necessary skills and knowledge to operate heavy machinery safely and efficiently.",
    logo: TrainingLogo,
    bgImage: TrainingBg
  },
  {
    id: "security-services",
    title: "Security Services",
    subtitle: "Our security services ensure the protection of mining facilities and assets. With advanced surveillance systems, trained personnel, and strategic protocols.",
    logo: SecurityLogo,
    bgImage: SecurityBg
  },
];

const serviceBoxStyles = {
  title: {
    color: "#fff", // White color
    fontWeight: "bold",
  },
  subtitle: {
    color: "#fff", // White color
    fontWeight: "bold",
  },
};

const DotsWrapper = styled.div`
  position: absolute;
  right: 100px;
  z-index: 2;
  @media (max-width: 960px) {
    right: 50px;
    top: 170px;
  }
  @media (max-width: 560px) {
    display: none;
  }
`;

const Emptyslot = styled.div`
  width: 50px;
  height: 50px;
  @media (max-width: 960px) {
    width: 20px;
  }
`;

const Wrapper = styled.section`
  width: 100%;
  padding: 10px 0;
  // overflow: hidden;

  .animate {
    animation: fadeInScale 1s forwards;
  }

  @keyframes fadeInScale {
    0% {
      opacity: 0;
      transform: scale(0.8);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  .font40.extraBold,
  .font15,
  .font15.semiBold,
  .font12 {
    color: #333 !important;
  }
`;

const Section = styled.div`
  width: 100%;
`;

const Container = styled.div`
  width: 95%;
  max-width: 1200px;
  margin: 0 auto;
  padding:15px;
`;

const Carousel = styled.div`
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  padding: 20px 0;
  margin: 0 -20px;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 20px;
  }

  .hovered {
    transform: scale(1.05);
    transition: transform 0.3s ease-in-out;
  }
`;

const ServiceBoxWrapper = styled.div`
  flex: 1 1 calc(20% - 10px);
  margin: 0 5px;
  padding: 80px 0;
  position: relative;
  color: white;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.7);
  transition: all 0.3s ease-in-out;
  opacity: 0.8;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;

  .background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover; /* Ensures the image covers the entire container */
    background-position: center; /* Centers the image */
    z-index: 0;
    transition: filter 0.5s ease-in-out;
  }

  &:hover .background {
    filter: blur(5px);
  }

  &:hover {
    transform: scale(1.05); /* Adds a scaling effect */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Adds a shadow effect */
  }

  .content {
    position: relative;
    z-index: 1;
    padding: 20px;
    text-align: center;
  }

  .service-title,
  .service-subtitle {
    position: relative;
    z-index: 1;
  }

  .service-subtitle {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    transition: white-space 0.3s ease-in-out;
  }

  &:hover .service-subtitle {
    white-space: normal;
  }

  @media (max-width: 860px) {
    margin-bottom: 20px;
    flex: none;
    width: 100%;
    padding: 60px 0;
  }

  @media (max-width: 480px) {
    padding: 40px 0;
  }
`;



const ServiceIcon = styled.img`
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
`;

const HeaderInfo = styled.div`
  margin-bottom: 40px;
  text-align: center;

  h1 {
    font-size: 2.5rem;
    color: #fff;
    margin-bottom: 10px;
  }

  p {
    font-size: 1rem;
    color: #fff;
  }
`;

const Advertising = styled.div`
  position: relative;
  margin-top: 40px;
`;

const AddLeft = styled.div`
  flex: 1;
  padding-right: 20px;
  
  h4 {
    color: #333;
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 10px;
    letter-spacing: 0.5px;
  }

  h2 {
    color: #222;
    font-size: 2.5rem;
    font-weight: 800;
    margin-bottom: 20px;
    line-height: 1.2;
  }

  p {
    color: #555;
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 30px;
    text-align: justify;
  }

  @media (max-width: 768px) {
    text-align: center;
    padding-right: 0;
  }

  @media (max-width: 480px) {
    h2 {
      font-size: 2rem;
    }

    h4 {
      font-size: 1rem;
    }

    p {
      font-size: 0.9rem;
      margin-bottom: 20px;
    }
  }
`;


const AddRightInner = styled.div`
  width: 100%; /* Ensure the container takes full width */
  max-width: 300px; /* Set a max-width for larger screens */
`;

const AddImgWrapper = styled.div`
  width: 100%; /* Ensure the image wrapper takes full width */
  max-width: 300px; /* Set a max-width for the image */
  max-height: 300px; /* Set a max-height for the image */
  border-radius: 50%;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin: 0 auto; /* Center the image */
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    filter: grayscale(100%);
    animation: colorTransition 5s linear infinite;
    transition: filter 0.3s ease-in-out;
  }

  /* Lighting Effect */
  &:after {
    content: '';
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    border-radius: 50%;
    border: 3px solid transparent;
    background: linear-gradient(60deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.1));
    background-size: 300% 300%;
    animation: lightingEffect 5s linear infinite;
    z-index: 2;
  }
  
  /* Animation Keyframes */
  @keyframes lightingEffect {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  /* Continuous color transition animation */
  @keyframes colorTransition {
    0% {
      filter: grayscale(100%);
    }
    50% {
      filter: grayscale(0%);
    }
    100% {
      filter: grayscale(100%);
    }
  }

  @media (max-width: 768px) {
    max-width: 250px;
    max-height: 250px;
  }

  @media (max-width: 480px) {
    max-width: 200px;
    max-height: 200px;
  }
`;

const AddRight = styled.div`
  flex: 1;
  display: flex;
  justify-content: center; /* Center the content horizontally */
  align-items: center; /* Center the content vertically */
  position: relative;
  padding: 0 20px; /* Add padding for small screens */
  
  @media (max-width: 768px) {
    padding: 0 10px;
  }
`;




const ImageRow = styled.div`
  display: flex;
  justify-content: flex-end;
`;


const ButtonsRow = styled.div`
  display: flex;
`;

const ButtonWrapper = styled.div`
  margin-right: 15px;
`;
