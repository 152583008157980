// servicesData.js
import MiningLogo from "../assets/img/serviceslogos/MiningServices.png";
import EngineeringLogo from "../assets/img/serviceslogos/Engineering.png";
import SafetyLogo from "../assets/img/serviceslogos/Safety.png";
import SecurityLogo from "../assets/img/serviceslogos/Security.png";
import TrainingLogo from "../assets/img/serviceslogos/trainingservices.png";

import MiningBg from "../assets/img/servicebgs/MiningServices.png";
import SafetyBg from "../assets/img/servicebgs/Saf.png";
import EngineeringBg from "../assets/img/servicebgs/Engineering.png";
import TrainingBg from "../assets/img/servicebgs/trainingservices.png";
import SecurityBg from "../assets/img/servicebgs/Security.png";

//Mining images
import miningimage1 from "../Data/mining/mining (1).jpg";
import miningimage2 from "../Data/mining/mining (2).jpg";
import miningimage3 from "../Data/mining/mining (3).jpg";
import miningimage4 from "../Data/mining/mining (4).jpg";
import miningimage5 from "../Data/mining/mining (5).jpg";
import miningimage6 from "../Data/mining/mining (6).jpg";
import miningimage7 from "../Data/mining/mining (7).jpg";
import miningimage8 from "../Data/mining/mining (8).jpg";
import miningimage9 from "../Data/mining/mining (9).jpg";
import miningimage10 from "../Data/mining/mining (10).jpg";

//Engineering Images
import Eimages1 from "../Data/engineering/engineering (2).jpg";import Eimages2 from "../Data/engineering/engineering (11).jpg";
import Eimages3 from "../Data/engineering/engineering (12).jpg";import Eimages4 from "../Data/engineering/engineering (13).jpg";
import Eimages5 from "../Data/engineering/engineering (14).jpg";import Eimages6 from "../Data/engineering/engineering (15).jpg";
import Eimages7 from "../Data/engineering/engineering (16).jpg";import Eimages8 from "../Data/engineering/engineering (8).jpg";
import Eimages9 from "../Data/engineering/engineering (9).jpg";

//Training Images
import Timages1 from "./training/Timages1.jpg"; import Timages2 from "./training/Timages2.jpg";
import Timages3 from "./training/Timages3.jpg"; import Timages4 from "./training/Timages4.jpg";
import Timages5 from "./training/Timages5.jpg"; import Timages6 from "./training/Timages6.jpg";

//Security Images
import Simages1 from "./security/security (1).png"; import Simages2 from "./security/security (2).png";
import Simages3 from "./security/security (3).png"; import Simages4 from "./security/security (4).png";
import Simages5 from "./security/security (5).png"; import Simages6 from "./security/security (6).png";
import Simages7 from "./security/security (7).png"; import Simages8 from "./security/security (8).png";



const ServicesData = [
  {
    id: "mining-services",
    title: "Mining Services",
    subtitle: "Comprehensive mining solutions",
    logo: MiningLogo,
    bgImage: MiningBg,
    description: "Our mining services offer a full suite of solutions from exploration to extraction.",
    overview: "Our mining services are tailored to meet the unique needs of each project. From exploration to extraction, we utilize cutting-edge technology and industry expertise to ensure the success and safety of your operations.",
    features: [
      "Exploration and assessment",
      "Resource extraction",
      "Ore processing",
      "Mine closure and reclamation",
    ],
    benefits: "Maximize efficiency and profitability while ensuring safety and sustainability in mining operations.",
    images: [
      miningimage1,
      miningimage2,
      miningimage3,
      miningimage4,
      miningimage5,miningimage6,miningimage7,miningimage8,miningimage9,miningimage10,
    ],
  },
  {
    id: "safety-services",
    title: "Safety Services",
    subtitle: "Ensuring safety and integrity",
    logo: SafetyLogo,
    bgImage: SafetyBg,
    description: "Our safety services encompass risk assessment and safety protocols implementation.",
    overview: "Safety is our top priority. We offer comprehensive safety services to identify risks, develop safety protocols, and ensure compliance with industry standards, protecting both personnel and assets.",
    features: [
      "Risk assessment",
      "Safety training",
      "Emergency response planning",
      "Compliance monitoring",
    ],
    benefits: "Create a safer work environment, reduce accidents, and comply with regulatory requirements.",
    images:[],
  },
  {
    id: "engineering-services",
    title: "Engineering Services",
    subtitle: "Innovative engineering solutions",
    logo: EngineeringLogo,
    bgImage: EngineeringBg,
    description: "From project planning and design to implementation and maintenance.",
    overview: "Our engineering services cover a wide range of expertise, including project planning, design, and implementation. We use state-of-the-art technology to deliver innovative solutions that enhance efficiency and performance.",
    features: [
      "Project planning",
      "Design and engineering",
      "Construction management",
      "System optimization",
    ],
    benefits: "Improve operational efficiency, reduce costs, and ensure project success through expert engineering services.",
    images:[Eimages1,Eimages2,Eimages3,Eimages4,Eimages5,Eimages6,Eimages7,Eimages8,Eimages9],
  },
  {
    id: "training-services",
    title: "Training Services",
    subtitle: "Empowering your workforce",
    logo: TrainingLogo,
    bgImage: TrainingBg,
    description: "Tailored training services for mining personnel.",
    overview: "Our training programs are designed to equip mining personnel with the skills and knowledge needed to operate heavy machinery safely and efficiently. We offer customized training solutions to meet the specific needs of your team.",
    features: [
      "Heavy machinery training",
      "Safety and compliance courses",
      "Skills development",
      "Customized training programs",
    ],
    benefits: "Enhance workforce competence, improve safety, and boost productivity through targeted training programs.",
    images: [Timages1, Timages2, Timages3, Timages4, Timages5, Timages6],
  },
  
  {
    id: "security-services",
    title: "Security Services",
    subtitle: "Protecting your assets",
    logo: SecurityLogo,
    bgImage: SecurityBg,
    description: "Ensuring the protection of mining facilities and assets.",
    overview: "Our security services are designed to protect your mining facilities and assets. We employ advanced surveillance systems, trained personnel, and strategic protocols to safeguard against threats.",
    features: [
      "Surveillance systems",
      "Security personnel",
      "Access control",
      "Incident response",
    ],
    benefits: "Ensure the security of your operations, prevent unauthorized access, and respond effectively to incidents.",
    images:[Simages1,Simages2,Simages3,Simages4,Simages5,Simages6,Simages7,Simages8],
  },
];

export default ServicesData;
