import React, { useState } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import LazyLoad from "react-lazyload";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";
// Components
import FullButton from "../Buttons/FullButton";
// Assets
import QuotesIcon from "../../assets/svg/Quotes";
import Dots from "../../assets/svg/Dots";
import Image1 from "../../assets/img/header-img4.png";
import Image1WebP from "../../assets/img/header-img4.webp";
import Image2 from "../../assets/img/header-img5.png";
import Image2WebP from "../../assets/img/header-img5.webp";
import Image3 from "../../assets/img/header-img6.png";
import Image3WebP from "../../assets/img/header-img6.webp";
import Image4 from "../../assets/img/header-img7.png";
import Image4WebP from "../../assets/img/header-img7.webp";
import Image5 from "../../assets/img/header-img8.png";
import Image5WebP from "../../assets/img/header-img8.webp";
import Image6 from "../../assets/img/header-img9.png";
import Image6WebP from "../../assets/img/header-img9.webp";

import { Snackbar, Alert } from "@mui/material";


export default function Header() {
  // eslint-disable-next-line no-unused-vars
  const [currentSlide, setCurrentSlide] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const navigate = useNavigate();
  
  const settings = {
    
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000, // 5 seconds
    afterChange: (current) => setCurrentSlide(current), 
    prevArrow: null,
    nextArrow: null,
  };

  const handleRegisterClick = () => {
    setSnackbarOpen(true);
  
    setTimeout(() => {
      
      navigate('/RegistrationForm');
    }, 3000); 
  };
  

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <Wrapper id="home">
      <LeftSide className="flexCenter">
        <div>
          <HeaderTitle className="extraBold font60">
            The African Dukes of Development.
          </HeaderTitle>
          <HeaderP className="font13 semiBold">
            Discover unparalleled mining solutions with Planup, where cutting-edge technology meets unparalleled expertise to unlock the full potential of your mining operations and drive unprecedented success in the industry.
          </HeaderP>
        </div>
        <BtnWrapper>
          <FullButton title="Register" action={handleRegisterClick} target="_blank" rel="noopener noreferrer" />
        </BtnWrapper>
      </LeftSide>
      <RightSide>
        <StyledSlider {...settings}>
          <LazyLoad height={200} offset={100}>
            <Picture>
              <source srcSet={Image1WebP} type="image/webp" />
              <Image src={Image1} alt="mining operation" />
            </Picture>
          </LazyLoad>
          <LazyLoad height={200} offset={100}>
            <Picture>
              <source srcSet={Image2WebP} type="image/webp" />
              <Image src={Image2} alt="mining equipment" />
            </Picture>
          </LazyLoad>
          <LazyLoad height={200} offset={100}>
            <Picture>
              <source srcSet={Image3WebP} type="image/webp" />
              <Image src={Image3} alt="mining site" />
            </Picture>
          </LazyLoad>
          <LazyLoad height={200} offset={100}>
            <Picture>
              <source srcSet={Image4WebP} type="image/webp" />
              <Image src={Image4} alt="mining workforce" />
            </Picture>
          </LazyLoad>
          <LazyLoad height={200} offset={100}>
            <Picture>
              <source srcSet={Image5WebP} type="image/webp" />
              <Image src={Image5} alt="mining landscape" />
            </Picture>
          </LazyLoad>
          <LazyLoad height={200} offset={100}>
            <Picture>
              <source srcSet={Image6WebP} type="image/webp" />
              <Image src={Image6} alt="nextbuilding" />
            </Picture>
          </LazyLoad>
        </StyledSlider>
        <QuoteWrapper className="flexCenter darkBg radius8">
          <QuotesWrapper>
            <QuotesIcon />
          </QuotesWrapper>
          <div>
            <p className="font15 whiteColor">
              <em>
                What you believe aligns your dreams. Your mentality directs how you attract what you believe. Imagination is the Preview to life coming attractions.
              </em>
            </p>
            <p
              className="font13 orangeColor textRight"
              style={{ marginTop: "10px" }}
            >
              Mr. Duke Lamptey
            </p>
          </div>
        </QuoteWrapper>
        <DotsWrapper>
          <Dots />
        </DotsWrapper>
      </RightSide>

      {/* Snackbar for showing alert */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleCloseSnackbar} severity="info" sx={{ width: '100%' }}>
          Please fill out the forms with the correct details and Submit.
        </Alert>
      </Snackbar>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  padding-top: 80px;
  width: 100%;
  height: 750px;
  display: flex;
  @media (max-width: 960px) {
    flex-direction: column;
    padding-top: 10px;
  }
  @media (max-width: 768px) {
    padding-top: 10px;
    font-size: 36px;
    line-height: 1.05;
    
  }
  @media (max-width: 560px) {
    padding-top: 80px;
    font-size: 30px;
    line-height: 1;
    height:780px;
  }
`;

const HeaderTitle = styled.h1`
  font-size: 50px;
  line-height: 1.2;
  font-weight: 800;
  color: #004085; /* Dark blue color */
  @media (max-width: 960px) {
    font-size: 48px;
    line-height: 1.1;
  }
  @media (max-width: 768px) {
    font-size: 36px;
    line-height: 1.05;
  }
  @media (max-width: 560px) {
    font-size: 30px;
    line-height: 1;
  }
`;

const LeftSide = styled.div`
  width: 35%;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
  text-align: center;

  @media (max-width: 960px) {
    padding-top: 100px;
    width: 100%;
    order: 2;
    margin: 50px 0;
  }
  @media (max-width: 560px) {
    margin: -90px 0 50px 0;
    padding-top: 140px;
    width:100%;
  }
`;

const BtnWrapper = styled.div`
  align-items: center;
  width: 100%;
  max-width: 190px;
  margin-top: -40px;

  @media (max-width: 960px) {
    margin: 0 auto;
    margin-top:-60px;
    width:40%;
  }
     @media (max-width: 560px) {
    padding-top:15px;
  }
`;


const RightSide = styled.div`
  width: 65%;
  height: 92%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
  }
    @media (max-width: 560px) {
    height:41%;
    width:100%;
  }
`;

const HeaderP = styled.div`
  max-width: 470px;
  padding: 25px 0 50px 0;
  font-size: 1.2rem;
  line-height: 1.8rem;
  color: #343a40; /* Dark gray color */
  @media (max-width: 960px) {
    padding: 15px 0 50px 0;
    text-align: center;
    max-width: 100%;
    font-size: 1.1rem;
  }
`;

const QuoteWrapper = styled.div`
  position: absolute;
  max-width: 330px;
  padding: 30px;
  top: 460px;
  z-index: 99;
  background-color: rgba(0, 64, 133, 0.8); /* Dark blue with transparency */
  border-radius: 8px;

  @media (max-width: 960px) {
    left: 86px;
  }
  @media (max-width: 560px) {
    top: 300px;
    max-width: 300px;
    max-height: 100px;
  }
`;

const QuotesWrapper = styled.div`
  position: absolute;
  left: -20px;
  top: -10px;
`;

const DotsWrapper = styled.div`
  position: absolute;
  right: 10px;
  z-index: 2;
  @media (max-width: 960px) {
    right: 100px;
    top: 170px;
  }
  @media (max-width: 560px) {
    left: 200px;
    top: 90px;
  }
`;

const StyledSlider = styled(Slider)`
  width: 100%;

  .slick-slide img {
    width: 100%;
    object-fit: cover;
    border-radius: 25px; /* Rounded edges */
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1); /* Shadow effect */
    transition: transform 0.3s ease, filter 0.3s ease; /* Smooth transition for hover effect */
  }
  .slick-slide img:hover {
    transform: scale(1.05); /* Scale up slightly on hover */
    filter: brightness(1.2); /* Brighten the image on hover */
  }
  .slick-slide {
    position: relative;
    perspective: 1000px;
  }
  .slick-slide div {
    transition: transform 0.3s ease;
  }
    .slick-prev,
.slick-next {
  display: none !important; /* Hide the arrows */
}

  .slick-slide:hover div {
    transform: rotateY(10deg); /* Rotate slightly on hover */
  }
`;

const Picture = styled.picture`
  display: block;
`;

const Image = styled.img`
  width: 100%; /* Adjust the width of the images */
  height: auto; /* Adjust the height of the images */
  transition: transform 0.3s ease, filter 0.3s ease; /* Smooth transition for hover effect */
  &:hover {
    transform: scale(1.05); /* Scale up slightly on hover */
    filter: brightness(1.2); /* Brighten the image on hover */
  }
`;
